@font-face {
  font-family: 'Poppins';
  src: url(./Poppins-Regular.woff);
}

@font-face {
  font-family: 'Poppins-Light';
  src: url(./Poppins-Light.woff);
}

@font-face {
  font-family: 'Poppins-Medium';
  src: url(./Poppins-Medium.woff);
}

@font-face {
  font-family: 'Poppins-Bold';
  src: url(./Poppins-Bold.woff);
}

@font-face {
  font-family: 'Poppins-ExtraBold';
  src: url(./Poppins-Bold.woff);
}