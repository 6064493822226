input {
  width: 100%;
  font-size: 24px;
  font-family: "Poppins-ExtraBold";
  color: #EEF2F9;
  background: transparent;
  outline: none;
  border: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

input::-webkit-input-placeholder {
  color: rgba(238, 242, 249, 0.5);
  font-size: 14px;
  font-family: "Poppins-Medium";
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}